// UI for auth
console.log('Auth');

// Required dependencies
import 'jquery';
global.$ = global.jQuery = jQuery;

// Assets
import '../../../theme/auth/style.scss';

import '../../components/naja';
import '../../components/netteForms';
import onDomReady from '../../utils/onDomReady';
import '../../components/naja-errorHandler';

import '../../components/bootstrap';
import initBootstrapToasts from '../../components/bootstrap-toast';

import '../../utils/utils';

import Register from '../../utils/Register';

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	(new Register()).registerNewContent($(event.detail.snippet));
    if (event.detail.snippet.id === 'snippet--flashes') {
		initBootstrapToasts();
    }
});

naja.addEventListener('complete', (event) => {
	// @todo: v modalech je to zakazane tak to muze delat problemy
    if (typeof event.detail.payload !== 'undefined' && typeof event.detail.payload.redirect !== 'undefined') {
        window.location.href = event.detail.payload.redirect;
    }
});

naja.formsHandler.netteForms = Nette;

onDomReady(() => {	

	naja.initialize();
	initBootstrapToasts();

	(new Register()).registerNewContent($('body'));
	
});

// Webpack HMR
if (module.hot) {
	module.hot.accept();
}